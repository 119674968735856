import { useQuery } from '@tanstack/react-query';
import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import {
	Checkbox,
	CheckboxGroup as UnstyledCheckboxGroup,
} from '../../components/checkbox-group';
import { get } from '../../utils/api';
import toSentence from '../../utils/to-sentence';

import {
	type FilterControlHeaderProps,
	type FilterControlProps,
	type IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import { type RoleFilter } from './types';

const Container = styled.div`
	display: flex;
	gap: 8px;
`;
const CheckboxGroup = styled(UnstyledCheckboxGroup)``;

function useRoles() {
	return useQuery({
		queryKey: ['search', 'role-filter-control', 'roles'],
		queryFn: async () =>
			get<{ name: string; people_count: number }[]>('/search/roles'),
		staleTime: Infinity,
	});
}

const FIELD_NAME: RoleFilter['fieldName'] = 'role';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<RoleFilter>) {
	const { data: roleNames } = useRoles();

	const handleRoleChange = useCallback(
		(newValue: string[]) => {
			if (newValue.length === 0) {
				onClear(FIELD_NAME);
			} else {
				onChange({
					fieldName: FIELD_NAME,
					value: newValue,
				});
			}
		},
		[onChange, onClear],
	);

	return (
		<Container>
			<CheckboxGroup
				label="roles"
				onChange={handleRoleChange}
				value={filter?.value || []}
			>
				{roleNames?.map(({ name }) => (
					<Checkbox key={name} value={name}>
						{name}
					</Checkbox>
				))}
			</CheckboxGroup>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<RoleFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Roles"
		/>
	);
}

const RoleFilterControl: IFilterControl<RoleFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (filter.value.length === 0) {
			return [];
		}
		return [
			`work in ${toSentence(filter.value, 'or')} ${pluralize(
				'role',
				filter.value.length,
			)}`,
		];
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default RoleFilterControl;
