import { useCallback } from 'react';

import { trackEvent, type ViewType } from '../utils/analytics';
import useLocalStorage from '../utils/hooks/use-local-storage';
import type ImmutableURLSearchParams from '../utils/immutable-url-search-params';

import useImmutableSearchParams from './use-stable-immutable-search-params';

export const SEARCH_QUERY_TYPES = [
	'conceptual',
	'keyword',
	'similar_companies',
] as const;
export type SearchQueryType = (typeof SEARCH_QUERY_TYPES)[number];

export const SEARCH_QUERY_TYPE_PARAM_KEY = 'qType';

function getSearchQueryTypeFromSearchParams(
	searchParams: ImmutableURLSearchParams,
	defaultQueryType: SearchQueryType = 'keyword',
): SearchQueryType {
	const queryTypeParam = (searchParams.get(SEARCH_QUERY_TYPE_PARAM_KEY)
		?? defaultQueryType) as SearchQueryType;

	return SEARCH_QUERY_TYPES.includes(queryTypeParam)
		? queryTypeParam
		: defaultQueryType;
}

export function buildSearchParamsFromSearchQueryType(
	queryType: SearchQueryType,
	searchParams: ImmutableURLSearchParams,
) {
	return searchParams.set(SEARCH_QUERY_TYPE_PARAM_KEY, queryType);
}

export default function useSearchQueryType(analyticsConfig?: {
	componentIdentifier: string;
	viewType: ViewType;
}) {
	const [searchParams, setSearchParams] = useImmutableSearchParams();
	const [mostRecentQueryType, setMostRecentQueryType] =
		useLocalStorage<SearchQueryType>(
			'AdvancedSearchMostRecentQueryType',
			'conceptual',
		);

	const handleQueryTypeChange = useCallback(
		(newQueryType: SearchQueryType) => {
			if (analyticsConfig) {
				trackEvent(
					'Update Search Query Type',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ queryType: newQueryType },
				);
			}

			setSearchParams((currentParams) =>
				currentParams.set(SEARCH_QUERY_TYPE_PARAM_KEY, newQueryType),
			);
			setMostRecentQueryType(newQueryType);
		},
		[analyticsConfig, setSearchParams, setMostRecentQueryType],
	);

	return {
		handleQueryTypeChange,
		queryType: getSearchQueryTypeFromSearchParams(
			searchParams,
			mostRecentQueryType,
		),
	};
}
