/**
 * @file
 * [Figma reference][1]
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=2036%3A24529&mode=dev
 */
import React from 'react';
import styled, { css } from 'styled-components';

import { colors, effects, fonts } from '..';

type DarkModeLightMode = 'dark' | 'light';

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	mode?: DarkModeLightMode;
	variant?: 'primary' | 'secondary' | 'ghost' | 'danger';
}

export const border = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorder}`,
	ghost: 'none',
	danger: 'none',
} as const;

const darkModeBorder = {
	primary: `1px solid ${colors.borderDark.subtle}`,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const borderHover = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorderHover}`,
	ghost: 'none',
	danger: 'none',
} as const;

const darkModeBorderHover = {
	primary: `1px solid ${colors.borderDark.subtleHover}`,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const borderActive = {
	primary: 'none',
	secondary: `1px solid ${colors.button.secondaryBorderActive}`,
	ghost: 'none',
	danger: 'none',
} as const;

const darkModeBorderActive = {
	primary: `1px solid ${colors.borderDark.subtleActive}`,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const borderFocus = {
	primary: `2px solid ${colors.semantic.focusHighContrast}`,
	secondary: `2px solid ${colors.semantic.focus}`,
	ghost: `2px solid ${colors.semantic.focus}`,
	danger: `2px solid ${colors.semantic.focus}`,
} as const;

const darkModeBorderFocus = {
	primary: `2px solid ${colors.semantic.focusHighContrast}`,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const borderColorDisabled = {
	primary: colors.button.disabled,
	secondary: colors.button.disabled,
	ghost: 'transparent',
	danger: colors.button.disabled,
} as const;

const darkModeBorderColorDisabled = {
	primary: `1px solid ${colors.borderDark.disabled}`,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const backgroundColors = {
	primary: colors.button.primary,
	secondary: colors.button.secondary,
	ghost: colors.button.ghost,
	danger: colors.button.danger,
} as const;

const darkModeBackgroundColors = {
	primary: colors.fieldDark.field,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const backgroundHoverColors = {
	primary: colors.button.primaryHover,
	secondary: colors.button.secondary,
	ghost: colors.button.ghostHover,
	danger: colors.button.dangerHover,
} as const;

const darkModeBackgroundHoverColors = {
	primary: colors.fieldDark.hover,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const backgroundActiveColors = {
	primary: colors.button.primaryActive,
	secondary: colors.button.secondary,
	ghost: colors.button.ghostActive,
	danger: colors.button.dangerActive,
} as const;

const darkModeBackgroundActiveColors = {
	primary: colors.fieldDark.hover,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const backgroundDisabledColors = {
	primary: colors.button.disabled,
	secondary: colors.button.disabled,
	ghost: 'transparent',
	danger: colors.button.disabled,
} as const;

const darkModeBackgroundDisabledColors = {
	primary: colors.fieldDark.disabled,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const textColors = {
	primary: colors.text.onColor,
	secondary: colors.text.primary,
	ghost: colors.text.interactive,
	danger: colors.text.onColor,
} as const;

const darkModeTextColors = {
	primary: colors.text.onColor,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const textHoverColors = {
	primary: colors.text.onColor,
	secondary: colors.text.primary,
	ghost: colors.text.interactiveHover,
	danger: colors.text.onColor,
} as const;

const darkModeTextHoverColors = {
	// Intentionally not colors.textDark.onColor
	primary: colors.text.onColor,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

export const textDisabledColors = {
	primary: colors.text.disabled,
	secondary: colors.text.disabled,
	ghost: colors.text.disabled,
	danger: colors.text.disabled,
} as const;

const darkModeTextDisabledColors = {
	primary: colors.textDark.disabled,
	secondary: 'none',
	ghost: 'none',
	danger: 'none',
};

/**
 * Which combinations of variant and mode have a non-zero width border?
 */
function buttonHasABorder(mode: DarkModeLightMode, variant: string) {
	return (
		(variant === 'secondary' && mode === 'light')
		|| (variant === 'primary' && mode === 'dark')
	);
}

export const buttonStyle = css<Props>`
	${fonts.paragraph.paragraph};
	align-items: center;
	background: ${({ mode = 'light', variant = 'primary' }) =>
		mode === 'light'
			? backgroundColors[variant]
			: darkModeBackgroundColors[variant]};
	border: ${({ mode = 'light', variant = 'primary' }) =>
		mode === 'light' ? border[variant] : darkModeBorder[variant]};
	border-radius: 8px;
	color: ${({ mode = 'light', variant = 'primary' }) =>
		mode === 'light' ? textColors[variant] : darkModeTextColors[variant]};
	cursor: pointer;
	display: flex;
	gap: 4px;
	justify-content: center;
	min-width: 80px;
	padding: ${({ mode = 'light', variant = 'primary' }) =>
		buttonHasABorder(mode, variant)
			? '6px 12px 6px 8px'
			: '7px 13px 7px 9px'};
	transition: background 0.1s ease-in-out, border-color 0.1s ease-in-out;
	white-space: nowrap;

	&:active {
		${effects.shadow.hover}
		background: ${({ mode = 'light', variant = 'primary' }) =>
			mode === 'light'
				? backgroundActiveColors[variant]
				: darkModeBackgroundActiveColors[variant]};
		border: ${({ mode = 'light', variant = 'primary' }) =>
			mode === 'light'
				? borderActive[variant]
				: darkModeBorderActive[variant]};
	}

	&:disabled {
		background: ${({ mode = 'light', variant = 'primary' }) =>
			mode === 'light'
				? backgroundDisabledColors[variant]
				: darkModeBackgroundDisabledColors[variant]};
		border-color: ${({ mode = 'light', variant = 'primary' }) =>
			mode === 'light'
				? borderColorDisabled[variant]
				: darkModeBorderColorDisabled[variant]};
		color: ${({ mode = 'light', variant = 'primary' }) =>
			mode === 'light'
				? textDisabledColors[variant]
				: darkModeTextDisabledColors[variant]};
		cursor: not-allowed;
	}

	&:not(:disabled) {
		&:hover {
			${effects.shadow.hover}
			background: ${({ mode = 'light', variant = 'primary' }) =>
				mode === 'light'
					? backgroundHoverColors[variant]
					: darkModeBackgroundHoverColors[variant]};
			border: ${({ mode = 'light', variant = 'primary' }) =>
				mode === 'light'
					? borderHover[variant]
					: darkModeBorderHover[variant]};
			color: ${({ mode = 'light', variant = 'primary' }) =>
				mode === 'light'
					? textHoverColors[variant]
					: darkModeTextHoverColors[variant]};
		}

		&:focus-visible {
			${effects.shadow.focus}
			transition: none;
			border: ${({ mode = 'light', variant = 'primary' }) =>
				mode === 'light'
					? borderFocus[variant]
					: darkModeBorderFocus[variant]};
			outline: none;

			/*
			 * Focus borders are 1px larger than their non-focused counterpart.
			 * To prevent the button from growing, we need to decrease padding by 1px.
			 */
			padding: ${({ mode = 'light', variant = 'primary' }) =>
				buttonHasABorder(mode, variant)
					? '5px 11px 5px 7px'
					: '5px 11px 5px 7px'};
		}
	}

	svg {
		height: 16px;
		width: 16px;
	}
`;
const Button = styled.button<Props>`
	${buttonStyle}
`;

export default function ButtonComponent(props: Props) {
	if (props.mode === 'dark' && (props.variant || 'primary') !== 'primary') {
		throw new Error(
			'Dark mode is currently only supported for the "primary" variant',
		);
	}

	return <Button {...props} />;
}
