/**
 * @file Safely encode/decode arbitrary string with base64
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
 * https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 */
function base64ToBytes(base64: string): string {
	const binString = atob(base64);
	return new TextDecoder().decode(
		Uint8Array.from(binString, (c) => c.charCodeAt(0)),
	);
}

function bytesToBase64(bytes: Uint8Array) {
	const binString = Array.from(bytes, (byte) =>
		String.fromCodePoint(byte),
	).join('');
	return btoa(binString);
}

export function base64Decode(encoded: string): string {
	return base64ToBytes(encoded);
}

export function base64Encode(str: string) {
	return bytesToBase64(new TextEncoder().encode(str));
}
