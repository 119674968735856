import { useCallback, useEffect } from 'react';

import { trackEvent, type ViewType } from '../utils/analytics';
import type ImmutableURLSearchParams from '../utils/immutable-url-search-params';

import { type SearchQueryType } from './use-search-query-type';
import useImmutableSearchParams from './use-stable-immutable-search-params';

export const ENTITY_TYPES = ['companies', 'people'] as const;
export type EntityType = (typeof ENTITY_TYPES)[number];

export const ENTITY_TYPE_PARAM_KEY = 'eType';
const ENTITY_TYPES_CACHE: { [key: string]: EntityType[] } = {};

export const DEFAULT_ENTITY_TYPE_FOR_QUERY_TYPE = {
	conceptual: 'companies',
	keyword: 'companies',
	similar_companies: 'companies',
} as const satisfies Record<SearchQueryType, EntityType>;

const VALID_ENTITY_TYPES_FOR_QUERY_TYPE: {
	readonly [K in SearchQueryType]: EntityType[];
} = {
	conceptual: ['companies'],
	keyword: ['companies', 'people'],
	similar_companies: ['companies'],
};

function buildCacheKey(entityTypes: EntityType[]) {
	return entityTypes.toSorted().join(',');
}

function getTypesFromCache(entityTypes: EntityType[]) {
	const cacheKey = buildCacheKey(entityTypes);
	if (!ENTITY_TYPES_CACHE[cacheKey]) {
		ENTITY_TYPES_CACHE[cacheKey] = entityTypes.toSorted();
	}
	return ENTITY_TYPES_CACHE[cacheKey];
}

function getEntityTypeFromSearchParams(
	searchParams: ImmutableURLSearchParams,
	queryType: SearchQueryType,
	defaultEntityType: EntityType = DEFAULT_ENTITY_TYPE_FOR_QUERY_TYPE[
		queryType
	],
): EntityType {
	return (
		(searchParams.get(ENTITY_TYPE_PARAM_KEY) as EntityType)
		|| defaultEntityType
	);
}

export function buildSearchParamsFromEntityType(
	entityType: EntityType,
	searchParams: ImmutableURLSearchParams,
) {
	return searchParams.set(ENTITY_TYPE_PARAM_KEY, entityType);
}

export default function useEntityType(
	queryType: SearchQueryType,
	mostRecentEntityType?: EntityType,
	analyticsConfig?: {
		componentIdentifier: string;
		viewType: ViewType;
	},
) {
	const [searchParams, setSearchParams] = useImmutableSearchParams();
	useEffect(() => {
		setSearchParams((currentParams) => {
			const currentEntityType = getEntityTypeFromSearchParams(
				currentParams,
				queryType,
				mostRecentEntityType,
			);

			if (
				VALID_ENTITY_TYPES_FOR_QUERY_TYPE[queryType].includes(
					currentEntityType,
				)
			) {
				return currentParams;
			} else {
				return currentParams.set(
					ENTITY_TYPE_PARAM_KEY,
					DEFAULT_ENTITY_TYPE_FOR_QUERY_TYPE[queryType],
				);
			}
		});
	}, [mostRecentEntityType, queryType, setSearchParams]);

	const handleEntityTypeSelect = useCallback(
		(entityType: EntityType, selected: boolean) => {
			if (analyticsConfig) {
				trackEvent(
					'Update Search Entity Type',
					analyticsConfig.componentIdentifier,
					analyticsConfig.viewType,
					{ entityType, selected },
				);
			}

			setSearchParams((currentParams) => {
				if (
					VALID_ENTITY_TYPES_FOR_QUERY_TYPE[queryType].includes(
						entityType,
					)
				) {
					return currentParams.set(ENTITY_TYPE_PARAM_KEY, entityType);
				} else {
					return currentParams.set(
						ENTITY_TYPE_PARAM_KEY,
						DEFAULT_ENTITY_TYPE_FOR_QUERY_TYPE[queryType],
					);
				}
			});
		},
		[analyticsConfig, queryType, setSearchParams],
	);

	const validEntityTypes = getTypesFromCache(
		VALID_ENTITY_TYPES_FOR_QUERY_TYPE[queryType],
	);

	return {
		entityType: getEntityTypeFromSearchParams(
			searchParams,
			queryType,
			mostRecentEntityType,
		),
		handleEntityTypeSelect,
		validEntityTypes,
	};
}
