import React, { lazy } from 'react';

import { type User } from '../authentication';
import authorizedRoute from '../components/authorized-route';

const loadComponent = () => import('./view');

const View = lazy(loadComponent);

function loader() {
	return loadComponent();
}

const BETA_TENANT_NAMES: string[] = ['Drive Capital', 'ZoCo'];
const BETA_GROUP_NAMES: string[] = ['LPs'];
const BETA_USER_EMAILS: string[] = [];

export function showNewSearch(user: User): boolean {
	return (
		(user.tenant != null && BETA_TENANT_NAMES.includes(user.tenant.name))
		|| BETA_USER_EMAILS.includes(user.email)
		|| user.groups.some((group) => BETA_GROUP_NAMES.includes(group))
	);
}

export const path = 'new-search';

export const routes = [
	authorizedRoute(
		{
			path,
			element: <View />,
			loader,
		},
		showNewSearch,
	),
];
