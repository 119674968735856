import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import {
	type AutoCompleteItem,
	UncontrolledAutoCompleteInput,
	useCustomLocations,
} from '../../locations';
import { FilterLabel } from '../../theme/components/filters';
import { XIcon } from '../../theme/icons/system';
import toSentence from '../../utils/to-sentence';

import {
	type FilterControlHeaderProps,
	type FilterControlProps,
	type IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import { type LocationFilter } from './types';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	justify-content: space-between;
	padding-top: 8px;
`;
const Locations = styled.ul`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
`;
const LocationLabel = styled.li`
	display: flex;
	gap: 2px;

	& > svg {
		height: 16px;
		width: 16px;
	}
`;

const FIELD_NAME: LocationFilter['fieldName'] = 'location';
function FilterControl({
	filter,
	onChange,
	onClear,
}: FilterControlProps<LocationFilter>) {
	const customLocations = useCustomLocations();
	const autoCompleteOptions = useMemo(
		() => ({
			excludedLocationTypes: [],
			suggestions: customLocations.toSorted((a, b) => {
				const aIsDriveway = a.name.toLowerCase().includes('driveway');
				const bIsDriveway = b.name.toLowerCase().includes('driveway');

				if (aIsDriveway && !bIsDriveway) {
					return -1;
				} else if (!aIsDriveway && bIsDriveway) {
					return 1;
				} else {
					return a.name.localeCompare(b.name);
				}
			}),
			prioritizedCountryCodes: ['CAN', 'USA'],
		}),
		[customLocations],
	);
	const handleLocationSelect = useCallback(
		(item: AutoCompleteItem) => {
			const alreadySelected = filter?.value.some(
				({ name }) => name === item.name,
			);
			if (alreadySelected) {
				return;
			}

			const newFilterValue = (filter?.value || []).concat([
				{
					name: item.name,
					type: item.type,
				},
			]);

			onChange({
				...filter,
				fieldName: FIELD_NAME,
				value: newFilterValue,
			});
		},
		[filter, onChange],
	);
	const handleLocationDeselect = useCallback(
		(locationName: string) => {
			if (!filter) {
				return;
			}
			const newValue = filter.value.filter(
				({ name }) => name !== locationName,
			);

			if (newValue.length === 0) {
				onClear(FIELD_NAME);
			} else {
				onChange({ ...filter, value: newValue });
			}
		},
		[filter, onChange, onClear],
	);

	return (
		<Container>
			<UncontrolledAutoCompleteInput
				ariaLabel="search-locations-filter"
				htmlId="search-locations-filter"
				options={autoCompleteOptions}
				onSelect={handleLocationSelect}
				placeholder="Try: 'The Driveway'"
			/>
			<Locations>
				{(filter?.value || []).map((location) => (
					<FilterLabel
						isActive
						key={location.name}
						label={
							<LocationLabel>
								<span>{location.name}</span>
								<XIcon />
							</LocationLabel>
						}
						onAction={() => {
							handleLocationDeselect(location.name);
						}}
					/>
				))}
			</Locations>
		</Container>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<LocationFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="Locations"
		/>
	);
}

const LocationFilterControl: IFilterControl<LocationFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (filter.value.length === 0) {
			return [];
		}
		return [
			`are located in ${toSentence(
				filter.value.map(({ name }) => name).toSorted(),
				'or',
			)}`,
		];
	},
	fieldName: FIELD_NAME,
	header: Header,
};

export default LocationFilterControl;
