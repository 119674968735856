import React, { type ComponentProps, useCallback, useState } from 'react';
import { Button, DialogTrigger, Popover } from 'react-aria-components';
import styled from 'styled-components';

import { colors, effects } from '../../theme';
import {
	type Props as ButtonProps,
	buttonStyle as buttonStyles,
} from '../../theme/components/button';
import { buttonStyles as controlButtonStyles } from '../../theme/components/control-button';
import { styles as iconButtonStyles } from '../../theme/components/icon-button';
import { ChevronDownIcon, ChevronUpIcon } from '../../theme/icons/arrows';
import { EllipsisHorizontalIcon } from '../../theme/icons/system';

import { Chevron } from './shared-styling/chevron';
import MenuPopover from './shared-styling/menu-popover';
import { MenuText } from './shared-styling/menu-text';

const StyledControlButton = styled(Button)`
	${controlButtonStyles}
`;
const StyledMenuButton = styled(Button)<ButtonProps>`
	${(props) => (props.mode || props.variant) && buttonStyles}

	${(props) => !(props.mode || props.variant) && effects.shadow.shadow}
	${(props) =>
		!(props.mode || props.variant)
		&& `
		align-items: center;
		appearance: none;
		background: ${colors.button.primary};
		border: none;
		border-radius: 8px;
		cursor: pointer;
		display: flex;
		gap: 4px;
		justify-self: stretch;
		padding: 0 0 0 12px;
		transition: all 0.1s ease-in-out;

		&:hover {
			background: ${colors.button.primaryHover};
		}
	`}
	&:hover {
		${(props) => !(props.mode || props.variant) && effects.shadow.hover}
		${effects.shadow.hover}
	}
`;
const StyledIconButton = styled(Button)<{ size?: 'default' | 'small' }>`
	${iconButtonStyles}
`;

interface Props {
	buttonMode?: ButtonProps['mode'];
	buttonVariant?: ButtonProps['variant'];
	children: React.ReactNode;
	label: string;
	onHoverStart?: () => void;
	placement?: ComponentProps<typeof Popover>['placement'];
}

export default function Menu({
	buttonMode,
	buttonVariant,
	children,
	label,
	onHoverStart,
	placement,
}: Props) {
	const [isOpen, setIsOpen] = useState(false);
	const useThemeButtonStyling = Boolean(buttonMode || buttonVariant);

	return (
		<DialogTrigger onOpenChange={setIsOpen}>
			<StyledMenuButton
				mode={buttonMode}
				onFocus={onHoverStart}
				onHoverStart={onHoverStart}
				variant={buttonVariant}
			>
				{!useThemeButtonStyling && (
					<>
						<MenuText>{label}</MenuText>{' '}
						<Chevron
							as={isOpen ? ChevronUpIcon : ChevronDownIcon}
						/>
					</>
				)}
				{useThemeButtonStyling && (
					<>
						<span>{label}</span>{' '}
						{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
					</>
				)}
			</StyledMenuButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}

type _BaseControlMenuProps = Props & {
	Icon: React.ComponentType;
};

type _ControlledControlMenuProps = {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
};
type _UncontrolledControlMenuProps = {
	isOpen?: never;
	onOpenChange?: never;
};

type ControlMenuProps = _BaseControlMenuProps &
	(_ControlledControlMenuProps | _UncontrolledControlMenuProps);

export function ControlMenu({
	Icon,
	children,
	isOpen,
	label,
	onHoverStart,
	onOpenChange,
	placement,
}: ControlMenuProps) {
	const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(false);

	const handleOpenChange = useCallback(
		(val: boolean) => {
			if (onOpenChange) {
				onOpenChange(val);
			} else {
				setUncontrolledIsOpen(val);
			}
		},
		[onOpenChange, setUncontrolledIsOpen],
	);

	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
			<StyledControlButton
				variant="secondary"
				active={isOpen ?? uncontrolledIsOpen}
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
			>
				{<Icon />}
				{label}
			</StyledControlButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}

type _BaseIconMenuProps = Omit<Props, 'label'> & {
	Icon?: React.ComponentType;
	buttonSize?: 'default' | 'small';
	disabled?: boolean;
	placement?: ComponentProps<typeof Popover>['placement'];
};

type _ControlledIconMenuProps = {
	isOpen: boolean;
	onOpenChange: (isOpen: boolean) => void;
};
type _UncontrolledIconMenuProps = {
	isOpen?: never;
	onOpenChange?: never;
};

type IconMenuProps = _BaseIconMenuProps &
	(_ControlledIconMenuProps | _UncontrolledIconMenuProps);

export function IconMenu({
	Icon = EllipsisHorizontalIcon,
	buttonSize,
	children,
	disabled = false,
	isOpen,
	onHoverStart,
	onOpenChange,
	placement,
}: IconMenuProps) {
	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={onOpenChange}>
			<StyledIconButton
				isDisabled={disabled}
				onHoverStart={onHoverStart}
				onFocus={onHoverStart}
				size={buttonSize}
			>
				{<Icon />}
			</StyledIconButton>
			<MenuPopover placement={placement}>{children}</MenuPopover>
		</DialogTrigger>
	);
}
