import React, { Key, useRef } from 'react';
import { DialogTrigger, Popover } from 'react-aria-components';

import SplitButton, { SplitControlButton } from '../buttons/split-button';

import MenuPopover from './shared-styling/menu-popover';

interface Props {
	children: React.ReactNode;
	isOpen: boolean;
	label: string;
	onAction: (action: Key) => void;
	onHoverStart?: (() => void) | undefined;
	onMenuClose?: () => void;
	primaryButtonAction: Key;
	secondaryMenuChildren: React.ReactElement;
}

export default function SplitMenu({
	onHoverStart,
	label,
	children,
	secondaryMenuChildren,
	onMenuClose,
	isOpen,
	primaryButtonAction,
	onAction,
}: Props) {
	const primaryButtonRef = useRef<HTMLButtonElement>(null);

	const handleOpenChange = (isMenuOpen: boolean) => {
		if (onMenuClose && !isMenuOpen) {
			onMenuClose();
		}
	};

	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
			<SplitButton
				label={label}
				primaryButtonAction={primaryButtonAction}
				secondaryMenuIsOpenOverride={isOpen}
				secondaryMenuChildren={secondaryMenuChildren}
				onHoverStart={onHoverStart}
				primaryButtonRef={primaryButtonRef}
				onAction={onAction}
			/>
			<MenuPopover triggerRef={primaryButtonRef}>{children}</MenuPopover>
		</DialogTrigger>
	);
}

type ControlMenuProps = Omit<Props, 'label' | 'secondaryMenuChildren'> & {
	active: React.ComponentPropsWithoutRef<typeof SplitControlButton>['active'];
	className?: string;
	label: React.ReactNode;
	popoverPlacement?: React.ComponentProps<typeof Popover>['placement'];
	secondaryMenuChildren: React.ReactNode;
	secondaryMenuPopverPlacement?: React.ComponentProps<
		typeof Popover
	>['placement'];
	variant?: React.ComponentPropsWithoutRef<
		typeof SplitControlButton
	>['variant'];
};

export function SplitControlMenu({
	active,
	children,
	isOpen,
	label,
	onAction,
	onHoverStart,
	onMenuClose,
	popoverPlacement,
	primaryButtonAction,
	secondaryMenuChildren,
	secondaryMenuPopverPlacement,
	variant,
}: ControlMenuProps) {
	const primaryButtonRef = useRef<HTMLButtonElement>(null);

	const handleOpenChange = (isMenuOpen: boolean) => {
		if (onMenuClose && !isMenuOpen) {
			onMenuClose();
		}
	};

	return (
		<DialogTrigger isOpen={isOpen} onOpenChange={handleOpenChange}>
			<SplitControlButton
				active={active}
				label={label}
				onAction={onAction}
				onHoverStart={onHoverStart}
				popoverPlacement={secondaryMenuPopverPlacement}
				primaryButtonAction={primaryButtonAction}
				primaryButtonRef={primaryButtonRef}
				secondaryMenuChildren={secondaryMenuChildren}
				secondaryMenuIsOpenOverride={isOpen}
				variant={variant}
			/>
			<Popover
				className="SplitControlMenu__Body"
				crossOffset={
					popoverPlacement === 'bottom right'
					&& secondaryMenuChildren != null
						? 28
						: void 0
				}
				placement={popoverPlacement}
				triggerRef={primaryButtonRef}
			>
				{children}
			</Popover>
		</DialogTrigger>
	);
}
