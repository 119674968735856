/**
 * These internal-only constants are defined in [Figma][1]. They should only be
 * used to define semantic color variables below.
 *
 * The scale corresponds to the 100-point units used by the CSS `font-weight`
 * property:
 *
 * - 100 (Thin) <- The lightest color
 * - 200 (Extra Light)
 * - 300 (Light)
 * - 400 (Regular) <- The baseline color
 * - 500 (Medium)
 * - 600 (Semi Bold)
 * - 700 (Bold) <- The darkest color
 *
 * [1]: https://www.figma.com/file/H0G18Iv8CzUO9pitJxDw65/Herbie-Design-System?node-id=20%3A113420&mode=dev

 * @internal
 */
const _palette = {
	aqua100: '#dae6ff',
	aqua200: '#b1c4ff',
	aqua300: '#7c9afc',
	aqua400: '#2d49f9',
	aqua500: '#1c29d1',
	aqua600: '#0813a5',
	aqua700: '#00006a',
	blue100: '#cfe6ff',
	blue200: '#a3cfff',
	blue300: '#73b6ff',
	blue400: '#007aff',
	blue500: '#005ec4',
	blue600: '#00428a',
	blue700: '#002e61',
	dust100: '#f7f7f5',
	dust200: '#edece8',
	dust300: '#e0e0dc',
	dust400: '#cfcdc6',
	dust500: '#999891',
	dust600: '#706f69',
	dust700: '#424240',
	gray100: '#ffffff',
	gray200: '#f0f0f0',
	gray300: '#dedede',
	gray400: '#c9c9c9',
	gray500: '#7f7f7f',
	gray600: '#606060',
	gray700: '#101010',
	green100: '#d5f1dd',
	green200: '#b0e5be',
	green300: '#87d79b',
	green400: '#24b64a',
	green500: '#1c8c39',
	green600: '#136228',
	green700: '#0e451c',
	indigo100: '#dfdff7',
	indigo200: '#c3c2f0',
	indigo300: '#a3a2e8',
	indigo400: '#5856d6',
	indigo500: '#4442a5',
	indigo600: '#302e74',
	indigo700: '#212151',
	orange100: '#ffebcf',
	orange200: '#ffd9a4',
	orange300: '#ffc574',
	orange400: '#ff9502',
	orange500: '#c47302',
	orange600: '#8a5001',
	orange700: '#613901',
	pink100: '#ffd7ea',
	pink200: '#ffb3d7',
	pink300: '#fe8bc2',
	pink400: '#fe2d91',
	pink500: '#c42370',
	pink600: '#89184e',
	pink700: '#611137',
	purple100: '#f0def9',
	purple200: '#e2c1f3',
	purple300: '#d3a0ec',
	purple400: '#af53dd',
	purple500: '#8740aa',
	purple600: '#5e2d77',
	purple700: '#432054',
	red100: '#ffdad7',
	red200: '#ffb8b4',
	red300: '#fe938d',
	red400: '#fe3b2f',
	red500: '#c42d24',
	red600: '#892019',
	red700: '#611612',
	yellow100: '#fff5cf',
	yellow200: '#ffeda3',
	yellow300: '#ffe373',
	yellow400: '#ffcc00',
	yellow500: '#c49d00',
	yellow600: '#8a6e00',
	yellow700: '#614e00',
} as const;

const colors = {
	background: {
		background: _palette.dust100,
		wrapper: _palette.dust200,
		inverse: _palette.gray700,
		brand: _palette.aqua400,
	},
	border: {
		subtle: _palette.gray300,
		subtleHover: _palette.gray400,
		subtleActive: _palette.gray400,
		active: _palette.gray500,
		strong: _palette.gray500,
		disabled: _palette.gray400,
		inverse: _palette.dust600,
		interactive: _palette.aqua400,
		interactiveHover: _palette.aqua600,
		danger: _palette.red400,
		success: _palette.green400,
	},
	borderDark: {
		subtle: _palette.gray500,
		subtleHover: _palette.gray400,
		subtleActive: _palette.gray400,
		active: _palette.gray300,
		strong: _palette.gray300,
		disabled: _palette.gray600,
		inverse: _palette.dust200,
		interactive: _palette.aqua300,
		danger: _palette.red300,
		success: _palette.green300,
	},
	button: {
		disabled: _palette.gray300,
		primary: _palette.aqua400,
		primaryHover: _palette.aqua600,
		primaryActive: _palette.aqua600,
		secondary: _palette.gray100,
		secondaryActive: _palette.gray200,
		secondaryBorder: _palette.gray300,
		secondaryBorderHover: _palette.gray400,
		secondaryBorderActive: _palette.gray400,
		danger: _palette.red400,
		dangerHover: _palette.red500,
		dangerActive: _palette.red500,
		ghost: 'transparent',
		ghostHover: _palette.aqua100,
		ghostActive: _palette.aqua200,
	},
	card: {
		/*
		 * These are intentionally custom colors outside of the palette. We need
		 * a subtle tone between gray100 and gray200 to stand out against the
		 * layer background.
		 *
		 * https://drivecapital.slack.com/archives/C055CPJFUHK/p1691518529535149?thread_ts=1690837931.241209&cid=C055CPJFUHK
		 */
		card: '#fbfbfb', // 70% gray100, 30% gray200
		hover: '#f5f5f5', // 30% gray100, 70% gray200
		header: _palette.dust100,
	},
	data: {
		blue: {
			iconPrimary: _palette.blue700,
			iconSecondary: _palette.blue500,
			textPrimary: _palette.blue700,
			textSecondary: _palette.blue600,
			textSecondaryHover: _palette.blue700,
			layer: _palette.blue200,
			layerSubtle: _palette.blue100,
			layerHover: _palette.blue300,
			border: _palette.blue500,
			borderHover: _palette.blue600,
			accent: _palette.blue400,
		},
		dust: {
			iconPrimary: _palette.dust700,
			iconSecondary: _palette.dust500,
			textPrimary: _palette.dust700,
			textSecondary: _palette.dust700,
			textSecondaryHover: _palette.dust700,
			layer: _palette.dust300,
			layerSubtle: _palette.dust200,
			layerHover: _palette.dust400,
			border: _palette.dust500,
			borderHover: _palette.dust600,
			accent: _palette.dust600,
		},
		green: {
			textPrimary: _palette.green700,
			textSecondary: _palette.green600,
			textSecondaryHover: _palette.green700,
			layer: _palette.green200,
			layerSubtle: _palette.green100,
			layerHover: _palette.green300,
			border: _palette.green500,
			borderHover: _palette.green600,
			accent: _palette.green400,
		},
		indigo: {
			textPrimary: _palette.indigo700,
			textSecondary: _palette.indigo600,
			textSecondaryHover: _palette.indigo700,
			layer: _palette.indigo200,
			layerSubtle: _palette.indigo100,
			layerHover: _palette.indigo300,
			border: _palette.indigo500,
			borderHover: _palette.indigo600,
			accent: _palette.indigo400,
		},
		orange: {
			iconPrimary: _palette.orange700,
			iconSecondary: _palette.orange500,
			textPrimary: _palette.orange700,
			textSecondary: _palette.orange600,
			textSecondaryHover: _palette.orange700,
			layer: _palette.orange200,
			layerSubtle: _palette.orange100,
			layerHover: _palette.orange300,
			border: _palette.orange500,
			borderHover: _palette.orange600,
			accent: _palette.orange400,
		},
		pink: {
			textPrimary: _palette.pink700,
			textSecondary: _palette.pink600,
			textSecondaryHover: _palette.pink700,
			layer: _palette.pink200,
			layerSubtle: _palette.pink100,
			layerHover: _palette.pink300,
			border: _palette.pink500,
			borderHover: _palette.pink600,
			accent: _palette.pink400,
		},
		purple: {
			textPrimary: _palette.purple700,
			textSecondary: _palette.purple600,
			textSecondaryHover: _palette.purple700,
			layer: _palette.purple200,
			layerSubtle: _palette.purple100,
			layerHover: _palette.purple300,
			border: _palette.purple500,
			borderHover: _palette.purple600,
			accent: _palette.purple400,
		},
		red: {
			iconPrimary: _palette.red700,
			iconSecondary: _palette.red500,
			textPrimary: _palette.red700,
			textSecondary: _palette.red600,
			textSecondaryHover: _palette.red700,
			layer: _palette.red200,
			layerSubtle: _palette.red100,
			layerHover: _palette.red300,
			border: _palette.red500,
			borderHover: _palette.red600,
			accent: _palette.red400,
		},
		yellow: {
			textPrimary: _palette.yellow700,
			textSecondary: _palette.yellow600,
			textSecondaryHover: _palette.yellow700,
			layer: _palette.yellow200,
			layerSubtle: _palette.yellow100,
			layerHover: _palette.yellow300,
			border: _palette.yellow500,
			borderHover: _palette.yellow600,
			accent: _palette.yellow400,
		},
	},
	field: {
		field: _palette.gray100,
		hover: _palette.dust200,
		disabled: _palette.dust300,
	},
	fieldDark: {
		field: _palette.dust700,
		hover: _palette.gray600,
		disabled: _palette.dust700,
	},
	icon: {
		primary: _palette.gray700,
		secondary: _palette.gray500,
		secondaryHover: _palette.gray700,
		helper: _palette.gray600,
		onColor: _palette.gray100,
		disabled: _palette.gray400,
		interactive: _palette.aqua400,
		interactiveHover: _palette.aqua600,
		interactiveActive: _palette.aqua600,
		danger: _palette.red500,
		dangerHover: _palette.red600,
		success: _palette.green500,
	},
	iconDark: {
		primary: _palette.gray100,
		secondary: _palette.gray400,
		secondaryHover: _palette.gray300,
		helper: _palette.gray600,
		onColor: _palette.gray700,
		disabled: _palette.gray600,
		interactive: _palette.aqua300,
		interactiveHover: _palette.aqua200,
		interactiveActive: _palette.aqua200,
		danger: _palette.red400,
		dangerHover: _palette.red300,
		success: _palette.green400,
	},
	layer: {
		layer: _palette.gray100,
		hover: _palette.gray200,
		active: _palette.gray300,
		activeHover: _palette.gray400,
		disabled: _palette.dust200,
		selected: _palette.aqua100,
		selectedHover: _palette.aqua200,
		selectedActive: _palette.aqua200,
		inverse: _palette.dust700,
	},
	semantic: {
		danger: _palette.red400,
		warning: _palette.yellow400,
		success: _palette.green400,
		focus: _palette.blue400,
		focusHighContrast: '#73B6FF',
		info: _palette.aqua400,
		utility: _palette.purple400,
		transparent: 'rgba(0, 0, 0, 0)',
	},
	semanticDark: {
		danger: _palette.red400,
		warning: _palette.yellow400,
		success: _palette.green400,
		focus: _palette.blue300,
		info: _palette.aqua400,
		utility: _palette.purple400,
		transparent: 'rgba(0, 0, 0, 0)',
	},
	text: {
		primary: _palette.gray700,
		secondary: _palette.gray500,
		secondaryHover: _palette.gray700,
		secondaryActive: _palette.gray700,
		helper: _palette.gray600,
		onColor: _palette.gray100,
		disabled: _palette.gray400,
		interactive: _palette.aqua400,
		interactiveHover: _palette.aqua600,
		interactiveActive: _palette.aqua600,
		danger: _palette.red400,
		dangerHover: _palette.red500,
		success: _palette.green400,
		successHover: _palette.green500,
		warning: _palette.gray700,
	},
	textDark: {
		primary: _palette.gray100,
		secondary: _palette.gray300,
		secondaryHover: _palette.gray300,
		secondaryActive: _palette.gray700,
		helper: _palette.gray600,
		onColor: _palette.gray700,
		disabled: _palette.gray600,
		interactive: _palette.aqua300,
		interactiveHover: _palette.aqua200,
		interactiveActive: _palette.aqua200,
		danger: _palette.red300,
		dangerHover: _palette.red200,
		success: _palette.green400,
		successHover: _palette.green300,
		warning: _palette.gray100,
	},
	toggle: {
		inactive: _palette.gray500,
		active: _palette.green400,
		disabled: _palette.gray400,
		control: _palette.gray100,
		controlDisabled: _palette.gray300,
	},
} as const;

export default colors;
